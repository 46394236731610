import axios from "axios";
import Config from "../../config/config.json";

export function addEntity(entity, values, networkId) {
  const networkPath = networkId ? `?networkId=${networkId}` : ``;

  return async (dispatch) => {
    dispatch(addEntityRequest());
    return axios
      .post(`${Config.api.endpoint}/${entity}${networkPath}`, values)
      .then((res) => {
        dispatch(addEntitySuccess(res.data));
      })
      .catch((err) => {
        dispatch(addEntityFailure(err));
      });
  };
}

export function editEntity(entity, values, networkId, id) {
  const networkPath = networkId ? `?networkId=${networkId}` : ``;

  return async (dispatch) => {
    dispatch(editEntityRequest());
    return axios
      .patch(`${Config.api.endpoint}/${entity}/${id}${networkPath}`, values)
      .then((res) => {
        dispatch(editEntitySuccess());
      })
      .catch((err) => {
        dispatch(editEntityFailure(err));
      });
  };
}

export const ACK_TOAST = "ACK_TOAST";
export function ackToast() {
  return {
    type: ACK_TOAST,
  };
}

export const EDIT_ENTITY_REQUEST = "EDIT_ENTITY_REQUEST";
function editEntityRequest() {
  return {
    type: EDIT_ENTITY_REQUEST,
  };
}

export const EDIT_ENTITY_SUCCESS = "EDIT_ENTITY_SUCCESS";
function editEntitySuccess() {
  return {
    type: EDIT_ENTITY_SUCCESS,
  };
}

export const EDIT_ENTITY_FAILURE = "EDIT_ENTITY_FAILURE";
function editEntityFailure(err) {
  return {
    type: EDIT_ENTITY_FAILURE,
    err,
  };
}

export const ADD_ENTITY_REQUEST = "ADD_ENTITY_REQUEST";
function addEntityRequest() {
  return {
    type: ADD_ENTITY_REQUEST,
  };
}

export const ADD_ENTITY_SUCCESS = "ADD_ENTITY_SUCCESS";
function addEntitySuccess(data) {
  return {
    type: ADD_ENTITY_SUCCESS,
    data,
  };
}

export const ADD_ENTITY_FAILURE = "ADD_ENTITY_FAILURE";
function addEntityFailure(err) {
  return {
    type: ADD_ENTITY_FAILURE,
    err,
  };
}

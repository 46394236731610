import Moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Row } from "reactstrap";
import styled from "styled-components";
import RenderHTML from "../../../components/RenderHTML/RenderHTML";
import { getTimezonesNames } from "../../../library/Utilities";

const RowTitle = styled.p`
  width: 100%;
  border-bottom: 1px solid #c3c3c3;
  margin-bottom: 20px;
`;

Moment.fn.zoneName = function () {
  var abbr = this.zoneAbbr();
  var label = getTimezonesNames()[abbr];
  return label ? `${abbr} (${label})` : abbr;
};

class DetailsTab extends Component {
  render() {
    const {
      details: { contact, status, notes, config },
    } = this.props;

    return (
      <Card>
        <Row form>
          <RowTitle>Notes</RowTitle>
          <RenderHTML>{notes}</RenderHTML>
          <br />
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.siteService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsTab);

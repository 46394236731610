import axios from "axios";

export function setToken(access_token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
  localStorage.setItem("access_token", access_token);
}

export function clearToken() {
  axios.defaults.headers.common["Authorization"] = "";
  localStorage.removeItem("access_token");
}

export function getRoles(role) {
  const roles = [
    {
      value: "gateguard",
      label: "Gate Guard",
    },
    {
      value: "siteadmin",
      label: "Site Admin",
    },
  ];

  if (role === "owneradmin" || role === "superadmin") {
    roles.push({
      value: "owneradmin",
      label: "Owner Admin",
    });
  }

  if (role === "superadmin") {
    roles.push({
      value: "superadmin",
      label: "Super Admin",
    });
  }

  return roles;
}

export function getTimezonesNames() {
  return {
    CST: "Central Standard Time",
    CDT: "Central Daylight Time",
    EST: "Eastern Standard Time",
    EDT: "Eastern Daylight Time",
    MST: "Mountain Standard Time",
    MDT: "Mountain Daylight Time",
    PST: "Pacific Standard Time",
    PDT: "Pacific Daylight Time",
  };
}

export function getTimezones() {
  return [
    {
      label: "Central Standard Time (CST)",
      timezone: "America/Chicago",
    },
    {
      label: "Eastern Standard Time (EST)",
      timezone: "America/New_York",
    },
    {
      label: "Mountain Standard Time (MST)",
      timezone: "America/Denver",
    },
    {
      label: "Pacific Standard Time (PST)",
      timezone: "America/Los_Angeles",
    },
  ];
}

export function relativeFormats() {
  return {
    sameDay: "[Today]",
    nextDay: "M/D/YYYY",
    nextWeek: "M/D/YYYY",
    lastDay: "M/D/YYYY",
    lastWeek: "M/D/YYYY",
    sameElse: "M/D/YYYY",
  };
}

export function debounce(func, wait) {
  let timeout;

  return function () {
    const action = () => {
      clearTimeout(timeout);
      func();
    };

    clearTimeout(timeout);
    timeout = setTimeout(action, wait);
  };
}

export function setUser(data) {
  window.user = {
    role: data.role,
    permissions: data.permissions,
  };

  if (data.company_id) {
    window.user["company_id"] = data.company_id;
  }

  if (data.site_id) {
    window.user["site_id"] = data.site_id;
  }

  if (data.networks) {
    window.user["network_id"] = data.networks;
  }

  Object.freeze(window.user);
}

export function getFavourites() {
  const favourites = localStorage.getItem("favourites");

  return favourites ? JSON.parse(favourites) : [];
}

export const states = [
  {
    code: "AL",
    name: "Alabama",
  },
  {
    code: "AK",
    name: "Alaska",
  },
  {
    code: "AZ",
    name: "Arizona",
  },
  {
    code: "AR",
    name: "Arkansas",
  },
  {
    code: "CA",
    name: "California",
  },
  {
    code: "CO",
    name: "Colorado",
  },
  {
    code: "CT",
    name: "Connecticut",
  },
  {
    code: "DE",
    name: "Delaware",
  },
  {
    code: "DC",
    name: "District Of Columbia",
  },
  {
    code: "FL",
    name: "Florida",
  },
  {
    code: "GA",
    name: "Georgia",
  },
  {
    code: "HI",
    name: "Hawaii",
  },
  {
    code: "ID",
    name: "Idaho",
  },
  {
    code: "IL",
    name: "Illinois",
  },
  {
    code: "IN",
    name: "Indiana",
  },
  {
    code: "IA",
    name: "Iowa",
  },
  {
    code: "KS",
    name: "Kansas",
  },
  {
    code: "KY",
    name: "Kentucky",
  },
  {
    code: "LA",
    name: "Louisiana",
  },
  {
    code: "ME",
    name: "Maine",
  },
  {
    code: "MD",
    name: "Maryland",
  },
  {
    code: "MA",
    name: "Massachusetts",
  },
  {
    code: "MI",
    name: "Michigan",
  },
  {
    code: "MN",
    name: "Minnesota",
  },
  {
    code: "MS",
    name: "Mississippi",
  },
  {
    code: "MO",
    name: "Missouri",
  },
  {
    code: "MT",
    name: "Montana",
  },
  {
    code: "NE",
    name: "Nebraska",
  },
  {
    code: "NV",
    name: "Nevada",
  },
  {
    code: "NH",
    name: "New Hampshire",
  },
  {
    code: "NJ",
    name: "New Jersey",
  },
  {
    code: "NM",
    name: "New Mexico",
  },
  {
    code: "NY",
    name: "New York",
  },
  {
    code: "NC",
    name: "North Carolina",
  },
  {
    code: "ND",
    name: "North Dakota",
  },
  {
    code: "OH",
    name: "Ohio",
  },
  {
    code: "OK",
    name: "Oklahoma",
  },
  {
    code: "OR",
    name: "Oregon",
  },
  {
    code: "PA",
    name: "Pennsylvania",
  },
  {
    code: "RI",
    name: "Rhode Island",
  },
  {
    code: "SC",
    name: "South Carolina",
  },
  {
    code: "SD",
    name: "South Dakota",
  },
  {
    code: "TN",
    name: "Tennessee",
  },
  {
    code: "TX",
    name: "Texas",
  },
  {
    code: "UT",
    name: "Utah",
  },
  {
    code: "VT",
    name: "Vermont",
  },
  {
    code: "VA",
    name: "Virginia",
  },
  {
    code: "WA",
    name: "Washington",
  },
  {
    code: "WV",
    name: "West Virginia",
  },
  {
    code: "WI",
    name: "Wisconsin",
  },
  {
    code: "WY",
    name: "Wyoming",
  },
];

import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 1rem;
`;

const TextEditor = ({ setRef, initialValue }) => {
  return (
    <Container>
      <Editor
        apiKey="49hs869a9raatk6um4kpxna9t07z72i00yr1xq753yzwy14w"
        onInit={(evt, editor) => setRef(editor)}
        initialValue={initialValue}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
            "source",
          ],
          menubar: "file edit view insert format tools table help",
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }, ul {list-style:disc}",
        }}
      />
    </Container>
  );
};

export default TextEditor;

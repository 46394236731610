import { BreadCrumbs } from "@alliancesafetycouncil/asc-breadcrumbs";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getSite } from "../../../actions/Sites/Sites";
import { HeaderActions } from "../../../components/HeaderActions/HeaderActions";
import TabDisplay from "../../../components/TabDisplay/TabDisplay";
import { Header } from "../../../containers/Layout/Header";
import DetailsTab from "./DetailsTab";
import HistoryTab from "./HistoryTab";
import SiteRequirementsTab from "./SiteRequirementsTab";

class SiteDetails extends Component {
  constructor(props) {
    super(props);

    let config = [
      {
        name: "Site Requirements",
        path: "/site-requirements",
        component: SiteRequirementsTab,
        default: true,
        exact: true,
      },
      {
        name: "Details",
        path: "/details",
        component: DetailsTab,
        exact: true,
      },
    ];

    if (props.auth) {
      config = [
        {
          name: "Site Requirements",
          path: "/site-requirements",
          component: SiteRequirementsTab,
          default: true,
          exact: true,
        },
        {
          name: "Details",
          path: "/details",
          component: DetailsTab,
          exact: true,
        },
        {
          name: "History",
          path: "/history",
          component: HistoryTab,
          exact: true,
        },
      ];
    }

    this.state = {
      emergencyModal: false,
      config: config,
    };
  }

  componentWillMount() {
    const { id } = this.props.match.params;

    this.props.GetSite(id);
  }

  render() {
    const { details, auth } = this.props;

    const { id } = this.props.match.params;

    const links = [
      {
        link: `/#/companies/company-details/${details.company.id}/sites`,
        title: details.company.name,
      },
      {
        title: details.name,
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-full">
        <Header>
          <div className="pr-6 w-4/6">
            <BreadCrumbs crumbs={links} />
          </div>
          <div className="w-2/6">{this.props.auth && <HeaderActions />}</div>
        </Header>
        <div className="row header flex-initial mx-4 h-20 flex items-center">
          <PageTitle>
            <i className="fal fa-building text-orange-500 mr-2"></i>
            {details.name}
          </PageTitle>
          {auth && <a href={`/#/sites/edit-site/${id}`}>Edit</a>}
        </div>
        <div className="mb-4 row content flex-auto mx-4">
          <TabDisplay
            {...this.props}
            auth={auth}
            siteId={id}
            enableFavourite={true}
            tabs={this.state.config}
            edit={{
              path: `#/sites/edit-site/${id}`,
              label: "Edit Site",
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.siteService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSite: (siteId) => dispatch(getSite(siteId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetails);

import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "./DocumentIcon";

const CellContainer = styled.div`
  display: flex;

  &.delete {
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const CellUndo = styled.p`
  display: flex;
  align-content: center;
  justify-content: center;
  color: #1b91c7;
  cursor: pointer;
`;

const DeleteItem = ({ row, callback, deleted }) => {
  const [state, setState] = useState(deleted);

  const handleState = (e) => {
    setState(!state);
    callback(row);
  };

  return (
    <CellContainer id="markDeleted" className="delete" onClick={(e) => handleState(e)}>
      {state ? <CellUndo>Undo</CellUndo> : <Icon className="fal fa-times-circle" />}
    </CellContainer>
  );
};

export default DeleteItem;

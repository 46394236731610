import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Sidebar from "react-sidebar";
import { SignOut } from "../../actions/Auth/Auth";
import LogoLight from "../../assets/img/LogoLight.svg";
import routes from "../../routes";
import { Sidenav } from "./Sidenav";

const mql = window.matchMedia(`(min-width: 768px)`);

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarDocked: mql.matches,

      showModal: false,
      text: {},
      action: null,
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

    mql.addEventListener("change", this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeEventListener("change", this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  loading = () => <div className="layout-loading"></div>;

  SignOut = () => {
    this.props.SignOut();
  };

  toggleSidebar = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  };

  render() {
    const { profile, role, companyId, auth } = this.props;

    return (
      <div>
        <div className="app">
          <Sidebar
            sidebar={<Sidenav SignOut={this.SignOut} Profile={profile} auth={auth} />}
            open={this.state.sidebarOpen}
            docked={this.state.sidebarDocked}
            onSetOpen={this.onSetSidebarOpen}
            sidebarDocked={this.props.sidebarDocked}
            shadow={false}
          >
            <div className="flex md:hidden bg-blue-900 px-1 items-center justify-center fixed w-full">
              <div onClick={this.toggleSidebar} className="absolute left-0 ml-2 p-2 text-white curser-pointer">
                <i className="fas fa-bars"></i>
              </div>
              <div className="py-3">
                <img src={LogoLight} alt="ReadyCheck" className="w-48" />
              </div>
            </div>
            <div className="app-body">
              <main className="main">
                <div className="h-screen flex flex-col py-0 w-full mt-12 md:mt-0">
                  <Switch>
                    {routes.reduce((acc, route, idx) => {
                      if (auth === false && route.auth === false) {
                        if (route.component) {
                          acc.push(
                            <Route
                              key={idx}
                              path={route.path}
                              exact={route.exact}
                              name={route.name}
                              render={(props) => (
                                <route.component {...this.props} {...props} toggleModal={this.toggleModal} />
                              )}
                            />
                          );
                        }
                      } else if (auth === true) {
                        if (route.component) {
                          acc.push(
                            <Route
                              key={idx}
                              path={route.path}
                              exact={route.exact}
                              name={route.name}
                              render={(props) => (
                                <route.component {...this.props} {...props} toggleModal={this.toggleModal} />
                              )}
                            />
                          );
                        }
                      }
                      return acc;
                    }, [])}
                    {role === "owneradmin" ? (
                      <Redirect from="/" to={`/companies/company-details/${companyId}/activity-report`} />
                    ) : (
                      <Redirect from="/" to={auth ? "/favourite-sites" : "/companies"} />
                    )}
                  </Switch>
                </div>
              </main>
            </div>
            {/* <Footer/> */}
          </Sidebar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: {
      role: "superadmin",
    },
    role: "superadmin",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SignOut: () => dispatch(SignOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

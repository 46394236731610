import { BreadCrumbs } from "@alliancesafetycouncil/asc-breadcrumbs";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getCompany } from "../../../actions/Companies/Companies";
import { HeaderActions } from "../../../components/HeaderActions/HeaderActions";
import TabDisplay from "../../../components/TabDisplay/TabDisplay";
import { Header } from "../../../containers/Layout/Header";
import DetailsTab from "./DetailsTab";
import SitesTab from "./SitesTab";

class CompanyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: [
        {
          name: "Sites",
          path: "/sites",
          component: SitesTab,
          exact: true,
          default: true,
          showEdit: true,
        },
        {
          name: "Details",
          path: "/details",
          component: DetailsTab,
          exact: true,
          showEdit: true,
        },
      ],
    };
  }

  componentWillMount() {
    this.props.GetCompany(this.props.match.params.id);
  }

  render() {
    const { details, auth } = this.props;
    const { id } = this.props.match.params;

    const links = [
      {
        title: details.name,
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-full">
        <Header>
          <div className="pr-6 w-4/6">
            <BreadCrumbs crumbs={links} />
          </div>
          <div className="w-2/6">{this.props.auth && <HeaderActions />}</div>
        </Header>
        <div className="row header flex-initial mx-4 h-20 flex items-center">
          <PageTitle>
            <i className="fal fa-building text-orange-500 mr-2"></i>
            {details.name}
          </PageTitle>
          {auth && <a href={`/#/companies/edit-company/${id}`}>Edit</a>}
        </div>
        <div className="mb-4 row content flex-auto mx-4">
          <TabDisplay
            {...this.props}
            tabs={this.state.config}
            edit={{
              path: `#/companies/edit-company/${this.props.match.params.id}`,
              label: "Edit Company",
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompany: (id) => dispatch(getCompany(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);

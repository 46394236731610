import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Row } from "reactstrap";
import styled from "styled-components";
import RenderHTML from "../../../components/RenderHTML/RenderHTML";

const RowTitle = styled.p`
  width: 100%;
  border-bottom: 1px solid #c3c3c3;
  margin-bottom: 20px;
`;

class DocumentDetailsTab extends Component {
  render() {
    const {
      requirementDetails: { name, notes },
    } = this.props;

    return (
      <Card>
        <Row form>
          <RowTitle>Notes</RowTitle>
          <RenderHTML>{notes}</RenderHTML>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requirementDetails: state.siteService.requirementDetails,
  };
};

export default connect(mapStateToProps, null)(DocumentDetailsTab);

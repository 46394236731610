import { BreadCrumbs } from "@alliancesafetycouncil/asc-breadcrumbs";
import Card from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Table from "@alliancesafetycouncil/asc-table";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCompanies } from "../../../actions/Companies/Companies";
import { HeaderActions } from "../../../components/HeaderActions/HeaderActions";
import { Header } from "../../../containers/Layout/Header";

class Companies extends Component {
  constructor(props) {
    super(props);

    // columnDefs will define the ag grid columns and cells
    const columnDefs = [{ headerName: "Company", field: "name", cellRenderer: "renderName" }];

    // this config will add buttons to the ag grid header component
    const config = [];

    // Update configs if authed
    if (props.auth) {
      columnDefs.push(
        { headerName: "Contact", field: "contact.name" },
        { headerName: "Email", field: "contact.email", cellRenderer: "renderEmail" },
        { headerName: "Phone", field: "contact.phone" }
      );

      config.push({
        type: "primary",
        size: "md",
        label: "Add Company",
        href: "/#/companies/add-company",
      });
    }

    this.state = {
      params: {
        direction: "asc",
        limit: 100,
        offset: 0,
        sort: "name",
      },
      columnDefs: columnDefs,
      defaultColDef: {
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
      },
      customCells: {
        renderName: (row) =>
          row.data && <Link to={`/companies/company-details/${row.data.id}/sites`}>{row.data.name}</Link>,
        renderEmail: (row) =>
          row.data && <a href={`mailto:${row?.data?.contact?.email}`}>{row?.data?.contact?.email}</a>,
      },
      config: config,
    };
  }

  componentWillMount() {
    const { direction, limit, offset, sort } = this.state.params;

    this.props.GetCompanies(direction, limit, offset, sort);
  }

  getGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  render() {
    const { data } = this.props;

    const links = [
      {
        title: "All Companies",
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-full">
        <Header>
          <div className="pr-6 w-4/6">
            <BreadCrumbs crumbs={links} />
          </div>
          <div className="w-2/6">{this.props.auth && <HeaderActions />}</div>
        </Header>
        <div className="row header flex-initial mx-4 h-20 flex items-center">
          <PageTitle>
            <i className="fal fa-building text-orange-500 mr-2"></i>All Companies
          </PageTitle>
        </div>
        <div className="mb-4 row content flex-auto mx-4">
          <Card type="main">
            <Table
              {...this.props}
              className="ag-theme-alpine flex flex-1 flex-col"
              columnDefs={this.state.columnDefs}
              getData={this.getCompanies}
              data={data}
              config={this.state.config}
              customCells={this.state.customCells}
              defaultColDef={this.state.defaultColDef}
              filter_type="any"
              getGridApi={this.getGridApi}
              headerConfig={this.state.config}
            />
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.companyService.busy,
    res: state.companyService.res,
    err: state.companyService.err,
    data: state.companyService.data,
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompanies: (direction, limit, offset, sort) => dispatch(getCompanies(direction, limit, offset, sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);

import {
  ADD_DOCUMENT_FAILURE,
  ADD_DOCUMENT_REQUEST,
  ADD_DOCUMENT_SUCCESS,
  CLEAR_SITES,
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_REQUIREMENT_FAILURE,
  GET_REQUIREMENT_REQUEST,
  GET_REQUIREMENT_SUCCESS,
  GET_SITES_FAILURE,
  GET_SITES_REQUEST,
  GET_SITES_SUCCESS,
  GET_SITE_FAILURE,
  GET_SITE_HISTORY_FAILURE,
  GET_SITE_HISTORY_REQUEST,
  GET_SITE_HISTORY_SUCCESS,
  GET_SITE_REQUEST,
  GET_SITE_REQUIREMENT_FAILURE,
  GET_SITE_REQUIREMENT_REQUEST,
  GET_SITE_REQUIREMENT_SUCCESS,
  GET_SITE_SUCCESS,
  UPDATE_SITE,
} from "../../actions/Sites/Sites";

const InitialState = {
  busy: false,
  res: false,
  error: false,
  requirementDetails: {},
  details: {
    name: "",
    company: {
      id: "",
      name: "",
    },
    contact: {
      name: "",
      email: "",
      phone: "",
    },
    notes: "",
    network: {
      id: "",
    },
    status: "",
  },
  data: [],
  requirements: [],
  documents: [],
  history: [],
};

export default function siteService(state = InitialState, action) {
  switch (action.type) {
    case ADD_DOCUMENT_REQUEST:
      return {
        ...state,
      };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
      };
    case ADD_DOCUMENT_FAILURE:
      return {
        ...state,
      };
    case GET_DOCUMENT_REQUEST:
      return {
        ...state,
      };
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: action.data.documents,
      };
    case GET_DOCUMENT_FAILURE:
      return {
        ...state,
      };
    case DELETE_ITEM_REQUEST:
      return {
        ...state,
      };
    case DELETE_ITEM_SUCCESS:
      state[action.entity][action.documentIndex].status = action.status;

      return {
        ...state,
      };
    case DELETE_ITEM_FAILURE:
      return {
        ...state,
      };
    case GET_SITES_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
        data: [],
        details: InitialState.details,
      };
    case GET_SITES_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        data: action.data.sites,
        total: action.data.sites.length,
      };
    case GET_SITES_FAILURE:
      return {
        ...state,
        busy: false,
        res: false,
        error: action.error,
      };
    case GET_SITE_REQUEST:
      return {
        ...state,
        busy: true,
        details: InitialState.details,
      };
    case GET_SITE_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        res: true,
        details: action.data,
      };
    case GET_SITE_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        res: false,
      };
    case GET_SITE_REQUIREMENT_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        res: false,
        requirements: [],
      };
    case GET_SITE_REQUIREMENT_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        res: true,
        requirements: action.data.requirements,
      };
    case GET_SITE_REQUIREMENT_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        res: false,
      };
    case GET_REQUIREMENT_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        res: false,
      };
    case GET_REQUIREMENT_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        res: true,
        requirementDetails: action.data,
      };
    case GET_REQUIREMENT_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        res: false,
      };
    case GET_SITE_HISTORY_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        res: false,
        history: [],
      };
    case GET_SITE_HISTORY_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        res: true,
        history: action.data.history,
      };
    case GET_SITE_HISTORY_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        res: false,
      };
    case UPDATE_SITE:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.data,
        },
      };
    case CLEAR_SITES:
      return {
        ...state,
        busy: false,
        res: true,
        data: [],
        total: 0,
      };
    default:
      return state;
  }
}

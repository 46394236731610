import Notifications from "@alliancesafetycouncil/asc-notifications";
// import SearchAnything from "@alliancesafetycouncil/asc-search-anything";
import SwitchApps from "@alliancesafetycouncil/asc-switch-apps";
import React from "react";
import { UserProfile } from "../UserProfile/UserProfile";

export const HeaderActions = () => {
  return (
    <div className="flex flex-row items-center float-right" style={{ opacity: 0 }}>
      <div className="mr-4">{/* <SearchAnything /> */}</div>
      <div className="mr-4">
        <SwitchApps />
      </div>
      <div className="mr-4">
        <Notifications />
      </div>
      <div>
        <UserProfile />
      </div>
    </div>
  );
};

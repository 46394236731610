import axios from "axios";
import Config from "../../config/config.json";

export function getSites(cb) {
  return async (dispatch) => {
    dispatch(getSitesRequest());
    return axios
      .get(`${Config.api.endpoint}/sites`)
      .then((res) => {
        dispatch(getSitesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSitesFailure("Could not fetch."));
      });
  };
}

export function getSite(id) {
  return async (dispatch) => {
    dispatch(getSiteRequest());
    return axios
      .get(`${Config.api.endpoint}/sites/${id}`)
      .then((res) => {
        dispatch(getSiteSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSiteFailure(err));
      });
  };
}

export function getSiteRequirementsById(id) {
  return async (dispatch) => {
    dispatch(getSiteRequirementRequest());
    return axios
      .get(`${Config.api.endpoint}/requirements?site_id=${id}`)
      .then((res) => {
        dispatch(getSiteRequirementSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSiteRequirementFailure("Could not fetch."));
      });
  };
}

export function getRequirement(id) {
  return async (dispatch) => {
    dispatch(getRequirementRequest());
    return axios
      .get(`${Config.api.endpoint}/requirements/${id}`)
      .then((res) => {
        dispatch(getRequirementSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getRequirementFailure("Could not fetch."));
      });
  };
}

export function getSiteHistoryById(id) {
  return async (dispatch) => {
    dispatch(getSiteHistoryRequest());
    return axios
      .get(`${Config.api.endpoint}/sites/${id}/history`)
      .then((res) => {
        dispatch(getSiteHistorySuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSiteHistoryFailure("Could not fetch."));
      });
  };
}

// used to retrieve a specific Site

export function getSiteByCompanyId(id) {
  return async (dispatch) => {
    dispatch(getSitesRequest());
    return axios
      .get(`${Config.api.endpoint}/sites?company_id=${id}`)
      .then((res) => {
        dispatch(getSitesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getSitesFailure("Could not fetch."));
      });
  };
}

export function getDocuments(id) {
  return async (dispatch) => {
    dispatch(getDocumentRequest());
    return axios
      .get(`${Config.api.endpoint}/documents?requirement_id=${id}`)
      .then((res) => {
        dispatch(getDocumentSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getDocumentFailure("Could not delete."));
      });
  };
}

export function getDocumentSignedURL(path) {
  const payload = {
    key: path,
  };

  return axios
    .post(`${Config.api.endpoint}/documents/signedurl`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function addDocuments(payload) {
  return async (dispatch) => {
    dispatch(addDocumentRequest());
    return axios
      .post(`${Config.api.endpoint}/documents`, payload)
      .then((res) => {
        dispatch(addDocumentSuccess(res.data));
        dispatch(getDocuments(payload.requirement_id));
      })
      .catch((err) => {
        dispatch(addDocumentFailure("Could not add."));
      });
  };
}

export function deleteItem(entity, id, index, payload) {
  return async (dispatch) => {
    dispatch(deleteItemRequest());
    return axios
      .patch(`${Config.api.endpoint}/${entity}/${id}`, payload)
      .then((res) => {
        dispatch(deleteItemSuccess(entity, index, payload.status));
      })
      .catch((err) => {
        dispatch(deleteItemFailure(`Could not delete ${entity}`));
      });
  };
}

export const ADD_DOCUMENT_REQUEST = "ADD_DOCUMENT_REQUEST";
function addDocumentRequest() {
  return {
    type: ADD_DOCUMENT_REQUEST,
  };
}

export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
function addDocumentSuccess(data) {
  return {
    type: ADD_DOCUMENT_SUCCESS,
    data,
  };
}

export const ADD_DOCUMENT_FAILURE = "ADD_DOCUMENT_FAILURE";
function addDocumentFailure(error) {
  return {
    type: ADD_DOCUMENT_FAILURE,
    error,
  };
}

export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST";
function getDocumentRequest() {
  return {
    type: GET_DOCUMENT_REQUEST,
  };
}

export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
function getDocumentSuccess(data) {
  return {
    type: GET_DOCUMENT_SUCCESS,
    data,
  };
}

export const GET_DOCUMENT_FAILURE = "GET_DOCUMENT_FAILURE";
function getDocumentFailure(error) {
  return {
    type: GET_DOCUMENT_FAILURE,
    error,
  };
}

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
function deleteItemRequest() {
  return {
    type: DELETE_ITEM_REQUEST,
  };
}

export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
function deleteItemSuccess(entity, documentIndex, status) {
  return {
    type: DELETE_ITEM_SUCCESS,
    entity,
    documentIndex,
    status,
  };
}

export const DELETE_ITEM_FAILURE = "DELETE_ITEM_FAILURE";
function deleteItemFailure(error) {
  return {
    type: DELETE_ITEM_FAILURE,
    error,
  };
}

export const GET_SITES_REQUEST = "GET_SITES_REQUEST";
function getSitesRequest() {
  return {
    type: GET_SITES_REQUEST,
  };
}

export const GET_SITES_SUCCESS = "GET_SITES_SUCCESS";
function getSitesSuccess(data) {
  return {
    type: GET_SITES_SUCCESS,
    data,
  };
}

export const GET_SITES_FAILURE = "GET_SITES_FAILURE";
function getSitesFailure(error) {
  return {
    type: GET_SITES_FAILURE,
    error,
  };
}

export const GET_SITE_REQUEST = "GET_SITE_REQUEST";
function getSiteRequest() {
  return {
    type: GET_SITE_REQUEST,
  };
}

export const GET_SITE_SUCCESS = "GET_SITE_SUCCESS";
function getSiteSuccess(data) {
  return {
    type: GET_SITE_SUCCESS,
    data,
  };
}

export const GET_SITE_FAILURE = "GET_SITE_FAILURE";
function getSiteFailure(err) {
  return {
    type: GET_SITE_FAILURE,
    err,
  };
}

export const GET_REQUIREMENT_REQUEST = "GET_REQUIREMENT_REQUEST";
function getRequirementRequest() {
  return {
    type: GET_REQUIREMENT_REQUEST,
  };
}

export const GET_REQUIREMENT_SUCCESS = "GET_REQUIREMENT_SUCCESS";
function getRequirementSuccess(data) {
  return {
    type: GET_REQUIREMENT_SUCCESS,
    data,
  };
}

export const GET_REQUIREMENT_FAILURE = "GET_REQUIREMENT_FAILURE";
function getRequirementFailure(err) {
  return {
    type: GET_REQUIREMENT_FAILURE,
    err,
  };
}

export const GET_SITE_REQUIREMENT_REQUEST = "GET_SITE_REQUIREMENT_REQUEST";
function getSiteRequirementRequest() {
  return {
    type: GET_SITE_REQUIREMENT_REQUEST,
  };
}

export const GET_SITE_REQUIREMENT_SUCCESS = "GET_SITE_REQUIREMENT_SUCCESS";
function getSiteRequirementSuccess(data) {
  return {
    type: GET_SITE_REQUIREMENT_SUCCESS,
    data,
  };
}

export const GET_SITE_REQUIREMENT_FAILURE = "GET_SITE_REQUIREMENT_FAILURE";
function getSiteRequirementFailure(err) {
  return {
    type: GET_SITE_REQUIREMENT_FAILURE,
    err,
  };
}

export const GET_SITE_HISTORY_REQUEST = "GET_SITE_HISTORY_REQUEST";
function getSiteHistoryRequest() {
  return {
    type: GET_SITE_HISTORY_REQUEST,
  };
}

export const GET_SITE_HISTORY_SUCCESS = "GET_SITE_HISTORY_SUCCESS";
function getSiteHistorySuccess(data) {
  return {
    type: GET_SITE_HISTORY_SUCCESS,
    data,
  };
}

export const GET_SITE_HISTORY_FAILURE = "GET_SITE_HISTORY_FAILURE";
function getSiteHistoryFailure(err) {
  return {
    type: GET_SITE_HISTORY_FAILURE,
    err,
  };
}

export const UPDATE_SITE = "UPDATE_SITE";
export function updateSite(data) {
  return {
    type: UPDATE_SITE,
    data,
  };
}

export const CLEAR_SITES = "CLEAR_SITES";
export function clearSites() {
  return {
    type: CLEAR_SITES,
  };
}

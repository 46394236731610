import { BreadCrumbs } from "@alliancesafetycouncil/asc-breadcrumbs";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import FormContainer from "../../../components/FormContainer/FormContainer";
import { HeaderActions } from "../../../components/HeaderActions/HeaderActions";
import { Header } from "../../../containers/Layout/Header";
import AddRequirementForm from "./AddRequirementForm";

class AddRequirement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
    };
  }

  setDocuments = (files) => {
    this.setState({
      files: files.map((file) => {
        return {
          name: file.name,
          key: file.key,
          type: file.type,
        };
      }),
    });
  };
  setRef = (ref) => {
    this.setState({
      editorRef: ref,
    });
  };

  setRef = (ref) => {
    this.setState({
      editorRef: ref,
    });
  };

  render() {
    const { details } = this.props;
    const { id } = this.props.match.params;

    const links = [
      {
        title: details.company.name,
      },
      {
        title: details.name,
      },
      {
        title: "Add Requirement",
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-full">
        <Header>
          <div className="pr-6 w-4/6">
            <BreadCrumbs crumbs={links} />
          </div>
          <div className="w-2/6">{this.props.auth && <HeaderActions />}</div>
        </Header>
        <div className="row header flex-initial mx-4 h-20 flex items-center">
          <PageTitle>
            <i className="fal fa-building text-orange-500 mr-2"></i>Add Requirement
          </PageTitle>
        </div>
        <div className="mb-4 content flex-auto mx-4">
          <FormContainer
            {...this.props}
            setRef={this.setRef}
            setDocuments={this.setDocuments}
            setRef={this.setRef}
            entity="requirements"
            action="add"
            redirect={`/sites/site-details/${id}/site-requirements`}
            Form={AddRequirementForm}
            handleValues={(values) => {
              values.documents = this.state.files;
              values.site_id = this.props.match.params.id;
              values.notes = this.state.editorRef.getContent();

              return values;
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.siteService.details,
    requirementDetails: state.siteService.requirementDetails,
  };
};

export default connect(mapStateToProps, null)(AddRequirement);

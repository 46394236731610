import { BreadCrumbs } from "@alliancesafetycouncil/asc-breadcrumbs";
import Card from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Table from "@alliancesafetycouncil/asc-table";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSites } from "../../../actions/Sites/Sites";
import { HeaderActions } from "../../../components/HeaderActions/HeaderActions";
import { FavouriteIcon } from "../../../components/Table/FavouriteIcon";
import { Header } from "../../../containers/Layout/Header";

class Sites extends Component {
  constructor(props) {
    super(props);

    // columnDefs will define the ag grid columns and cells
    let columnDefs = [
      { headerName: "Site", field: "name", cellRenderer: "renderSiteLink" },
      { headerName: "Company", field: "company.name", cellRenderer: "renderCompanyLink" },
      { headerName: "Site Requirements", field: "requirements" },
    ];

    // this config will add buttons to the ag grid header component
    let config = [];

    // Update configs if authed
    if (props.auth) {
      columnDefs = [
        { headerName: "Site", field: "name", cellRenderer: "renderSiteLink" },
        { headerName: "Company", field: "company.name", cellRenderer: "renderCompanyLink" },
        { headerName: "Contact", field: "contact.name" },
        { headerName: "Phone", field: "contact.phone" },
        { headerName: "Site Requirements", field: "requirements" },
        {
          headerName: "Add to Favorites",
          field: "favourite",
          maxWidth: 130,
          suppressMenu: true,
          cellRenderer: "renderFavourite",
        },
      ];

      config.push({
        type: "primary",
        size: "md",
        label: "Add Site",
        href: "/#/sites/add-site",
      });
    }

    this.state = {
      params: {
        direction: "asc",
        limit: 100,
        offset: 0,
        sort: "id",
      },
      columnDefs: columnDefs,
      defaultColDef: {
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
      },
      customCells: {
        renderSiteLink: (row) => {
          return <Link to={`/sites/site-details/${row.data.id}/site-requirements`}>{row.data.name}</Link>;
        },
        renderCompanyLink: (row) => {
          return <Link to={`/companies/company-details/${row.data.id}/sites`}>{row.data.company.name}</Link>;
        },
        renderFavourite: (row) => {
          return <FavouriteIcon siteId={row.data.id} />;
        },
      },
      config: config,
    };
  }

  componentWillMount() {
    const { direction, limit, offset, sort } = this.state.params;

    this.props.GetSites(direction, limit, offset, sort);
  }

  getGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  render() {
    const links = [
      {
        title: "All Sites",
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-full">
        <Header>
          <div className="pr-6 w-4/6">
            <BreadCrumbs crumbs={links} />
          </div>
          <div className="w-2/6">{this.props.auth && <HeaderActions />}</div>
        </Header>
        <div className="row header flex-initial mx-4 h-20 flex items-center">
          <PageTitle>
            <i className="fal fa-building text-orange-500 mr-2"></i>All Sites
          </PageTitle>
        </div>
        <div className="mb-4 row content flex-auto mx-4">
          <Card type="main">
            <Table
              {...this.props}
              className="ag-theme-alpine flex flex-1 flex-col"
              columnDefs={this.state.columnDefs}
              config={this.state.config}
              customCells={this.state.customCells}
              defaultColDef={this.state.defaultColDef}
              filter_type="any"
              getGridApi={this.getGridApi}
              headerConfig={this.state.config}
            />
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    data: state.siteService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSites: (direction, limit, offset, sort) => dispatch(getSites(direction, limit, offset, sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sites);

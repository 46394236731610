import Table from "@alliancesafetycouncil/asc-table";
import Moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getSiteHistoryById } from "../../../actions/Sites/Sites";

const ActionCell = styled.div`
  height: 100%;
  padding: 0px 17px;

  &.Edited {
    color: black;
    background: AliceBlue;
    text-align: justify;
  }
  &.Added {
    color: black;
    background: HoneyDew;
  }
  &.Inactive {
    color: black;
    background: LavenderBlush;
  }
`;

var commonWidth = 50;

// const data = [
//   {
//     id: 0,
//     date: "date",
//     action: "Edited",
//     requirement: "test",
//     author: "lloyd",
//   },
// ];

class HistoryTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: "Date",

          field: "date",
          cellRenderer: (data) => {
            return data.data.date && this.formatTime(data.data.date);
          },
          resizable: true,
        },
        {
          headerName: "Action",
          width: commonWidth,
          field: "action",
          cellRenderer: "renderAction",
          resizable: true,
          cellClass: "renderAction",
        },
        { headerName: "Requirement", field: "requirement", cellRenderer: "renderName", resizable: true },
        { headerName: "Author", field: "author", resizable: true },
      ],
      customCells: {
        renderName: (row) => {
          return (
            <Link to={`/requirements/requirement-details/${row.data.requirement.id}/details`}>
              {row.data.requirement.name}
            </Link>
          );
        },
        renderAction: (row) => {
          return <ActionCell className={row.value}>{row.value}</ActionCell>;
        },
      },
      options: [],
      config: [
        {
          type: "secondary",
          size: "md",
          label: "Export",
          action: () => this.gridApi.exportDataAsExcel(),
        },
      ],
    };
  }

  componentWillMount() {
    const id = this.props.match.params.id;

    this.props.GetSiteHistoryById(id);
  }

  getGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  formatTime = (value) => {
    return Moment(value).format("LLLL");
  };

  render() {
    const { history } = this.props;

    return (
      <Table
        {...this.props}
        className="ag-theme-alpine flex flex-1 flex-col"
        columnDefs={this.state.columnDefs}
        getData={this.GetSiteHistoryById}
        data={history}
        config={this.state.config}
        customCells={this.state.customCells}
        filter_type="any"
        getGridApi={this.getGridApi}
        headerConfig={this.state.config}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    history: state.siteService.history,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSiteHistoryById: (id) => dispatch(getSiteHistoryById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);

import ASCButton from "@alliancesafetycouncil/asc-button";
import MainCard from "@alliancesafetycouncil/asc-card";
import React from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { Field, Form, reduxForm } from "redux-form";
import TextInput from "../../../components/FormContainer/TextInput";
import TextEditor from "../../../components/TextEditor/TextEditor";
import { required } from "../../../library/Validation";

let AddCompanyForm = ({
  handleSubmit,
  pristine,
  submitting,
  initialValues,
  setRef,
  companies = ["Select Company"],
  sites = [],
  getSites,
  history,
  siteBusy,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name"
                id="companyName"
                placeholder=""
                label="Company Name"
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="contactName"
                name="contact.name"
                className="form-control"
                label="Contact"
                validate={[required]}
                component={TextInput}
              ></Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="contactEmail"
                name="contact.email"
                className="form-control"
                label="Email"
                validate={[required]}
                component={TextInput}
              ></Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="contactPhone"
                name="contact.phone"
                className="form-control"
                label="Phone"
                validate={[required]}
                component={TextInput}
              ></Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <TextEditor setRef={setRef} initialValue={initialValues} />
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={pristine || submitting}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

AddCompanyForm = reduxForm({
  form: "addCompany",
})(AddCompanyForm);

export default AddCompanyForm;

import React from "react";
import styled from "styled-components";

export const Icon = styled.i`
  color: ${(p) => p.color};
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &.render {
    margin-right: 5px;
    justify-content: center;
  }

  &.delete {
    position: absolute;
    font-size: 16px;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
  }
`;

export const DocumentIcon = ({ type, color }) => {
  const icon = {
    PDF: <Icon color={color} className="fal fa-file-pdf fa-2x render" />,
    pdf: <Icon color={color} className="fal fa-file-pdf fa-2x render" />,
    doc: <Icon color={color} className="fal fa-file-word fa-2x render" />,
    docx: <Icon color={color} className="fal fa-file-word fa-2x render" />,
    "Microsoft Word": <Icon color={color} className="fal fa-file-word fa-2x render" />,
  }[type];

  return icon || <Icon color={color} className="fal fa-file-word fa-2x render" />;
};

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authService from "./Auth/Auth";
import companyService from "./Companies/Companies";
import entityService from "./Entity/Entity";
import siteService from "./Sites/Sites";

const rootReducer = combineReducers({
  authService,
  companyService,
  siteService,
  entityService,
  form: formReducer,
});

export default rootReducer;

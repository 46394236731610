import React, { Component } from "react";
import { connect } from "react-redux";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { ConfigureProfile, Verify, VerifyFailure } from "./actions/Auth/Auth";
import "./App.scss";
import Layout from "./containers/Layout";
import SignIn from "./views/Pages/SignIn/SignIn";

const PrivateRoute = ({ auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth === true) {
          return rest.render();
        }

        if (auth === false) {
          return (
            <Redirect
              to={{
                pathname: "/public",
                state: { from: rest.location },
              }}
            />
          );
        }
      }}
    />
  );
};

// Loading
const loading = () => (
  <div>
    <span>Loading...</span>
  </div>
);

class App extends Component {
  checkAuth = () => {
    const { Verify, VerifyFailure, ConfigureProfile } = this.props;

    const token = localStorage.getItem("access_token");
    const role = window.user.role;

    if (token) {
      Verify(token);
    } else {
      VerifyFailure();
    }

    if (role) {
      ConfigureProfile({
        role,
      });
    }
  };

  // soft verify if there is a token in sessionStorage
  componentWillMount() {
    this.checkAuth();
  }

  render() {
    const { auth } = this.props;

    return (
      <HashRouter>
        <Switch>
          <Route path="/sign-in" name="SignIn" render={(props) => <SignIn {...props} />} />
          <Route auth={auth} path="/" name="layout" render={(props) => <Layout auth={auth} {...props} />} />
        </Switch>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authService.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Verify: (token) => dispatch(Verify(token)),
    VerifyFailure: () => dispatch(VerifyFailure()),
    ConfigureProfile: (data) => dispatch(ConfigureProfile(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

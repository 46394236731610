import {
  GET_COMPANIES_FAILURE,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  SET_COMPANIES_TOTAL,
  UPDATE_COMPANY,
  VERIFY_ENTRY_CRITERIA_FAILURE,
  VERIFY_ENTRY_CRITERIA_REQUEST,
  VERIFY_ENTRY_CRITERIA_SUCCESS,
} from "../../actions/Companies/Companies";

const InitialState = {
  busy: false,
  res: false,
  error: false,
  details: {
    name: "",
    contact: {
      name: "",
      email: "",
      phone: "",
    },
    notes: "",
    config: {
      features: [],
    },
    network: {
      id: "",
    },
    status: "",
  },
  data: [],
  total: 0,
};

export default function companyService(state = InitialState, action) {
  switch (action.type) {
    case VERIFY_ENTRY_CRITERIA_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
      };
    case VERIFY_ENTRY_CRITERIA_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
      };
    case VERIFY_ENTRY_CRITERIA_FAILURE:
      return {
        ...state,
        busy: false,
        res: false,
        error: true,
      };
    case GET_COMPANIES_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
        data: action.data,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        data: action.data.companies,
        total: action.data.companies.length,
      };
    case GET_COMPANIES_FAILURE:
      return {
        ...state,
        busy: false,
        res: false,
        error: action.error,
      };
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        busy: true,
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        busy: false,
        error: false,
        res: true,
        details: {
          ...action.data,
          hasMusterFeature: action.data.config && action.data.config.features.includes("muster"),
          hasBlockFeature: action.data.config && action.data.config.features.includes("block"),
        },
      };
    case GET_COMPANY_FAILURE:
      return {
        ...state,
        busy: false,
        error: true,
        res: false,
      };
    case UPDATE_COMPANY:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.data,
        },
      };
    case SET_COMPANIES_TOTAL:
      return {
        ...state,
        total: action.data,
      };
    default:
      return state;
  }
}

import Table from "@alliancesafetycouncil/asc-table";
import Moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { deleteItem, getSiteRequirementsById } from "../../../actions/Sites/Sites";
import DeleteItem from "../../../components/Table/DeleteItem";

class SiteRequirementsTab extends Component {
  constructor(props) {
    super(props);

    // columnDefs will define the ag grid columns and cells
    let columnDefs = [
      { headerName: "Requirement", field: "name", cellClass: "useDelete", cellRenderer: "renderName" },
      {
        headerName: "Last Updated",
        field: "last_updated",
        cellRenderer: (data) => {
          return data.data.last_updated && this.formatTime(data.data.last_updated);
        },
        cellClass: "useDelete",
      },
      {
        headerName: "",
        field: "status",
        maxWidth: 75,
      },
    ];

    // this config will add buttons to the ag grid header component
    let config = [];

    // Update configs if authed
    if (props.auth) {
      columnDefs = [
        { headerName: "Requirement", field: "name", cellClass: "useDelete", cellRenderer: "renderName" },
        { headerName: "Author", field: "author", cellClass: "useDelete" },
        { headerName: "Last Action", field: "last_action", cellClass: "useDelete" },
        {
          headerName: "Last Updated",
          field: "last_updated",
          cellRenderer: (data) => {
            return data.data.last_updated && this.formatTime(data.data.last_updated);
          },
          cellClass: "useDelete",
        },
        {
          headerName: "",
          field: "status",
          cellRenderer: "DeleteDocument",
          maxWidth: 75,
        },
      ];

      config.push({
        type: "primary",
        size: "md",
        label: "Add Requirement",
        action: () => "null",
        href: `/#/requirements/add-requirement/${props.match.params.id}`,
      });
    }

    this.state = {
      params: {
        direction: "asc",
        limit: 100,
        offset: 0,
        sort: "id",
      },
      columnDefs: columnDefs,
      customCells: {
        renderName: (row) => {
          return <Link to={`/requirements/requirement-details/${row.data.id}/details`}>{row.data.name}</Link>;
        },
        DeleteDocument: (row) => (
          <DeleteItem row={row} callback={this.deleteCallback} deleted={row.data.status === "inactive"} />
        ),
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
      },
      config: config,
    };
  }

  deleteCallback = (row) => {
    const { requirements } = this.props;

    requirements[row.rowIndex].status = requirements[row.rowIndex].status === "inactive" ? "active" : "inactive";

    const status = {
      status: requirements[row.rowIndex].status,
    };

    this.gridApi.applyTransaction({ update: [row.data] });
    this.props.DeleteRequirement(row.data.id, row.rowIndex, status);
  };

  componentWillMount() {
    const id = this.props.match.params.id;

    this.props.GetSiteRequirementsById(id);
  }

  getGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  formatTime = (value) => {
    return Moment(value).format("LLLL");
  };

  render() {
    const { requirements } = this.props;

    return (
      <Table
        className="ag-theme-alpine flex flex-1 flex-col"
        columnDefs={this.state.columnDefs}
        getData={this.GetSitesRequirementsById}
        data={requirements}
        config={this.state.config}
        customCells={this.state.customCells}
        defaultColDef={this.state.defaultColDef}
        getGridApi={this.getGridApi}
        filter_type="any"
        headerConfig={this.state.config}
        rowClassRules={{
          isDeleted: (params) => {
            return params.data.status === "inactive";
          },
        }}
        defaultFilter={{
          column: "status",
          value: "active",
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    requirements: state.siteService.requirements,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSiteRequirementsById: (id) => dispatch(getSiteRequirementsById(id)),
    DeleteRequirement: (id, index, status) => dispatch(deleteItem("requirements", id, index, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteRequirementsTab);

import axios from "axios";
import Config from "../../config/config.json";
import { clearToken, setToken, setUser } from "../../library/Utilities";

export function getAuth(username, password) {
  if (!username || !password) {
    return async (dispatch) => {
      dispatch(SignInFailure("Incorrect email or password. Please try again."));
    };
  }

  return async (dispatch) => {
    dispatch(SignInRequest());
    return axios
      .post(`${Config.api.endpoint}/authenticate`, {
        email: username,
        password: password,
      })
      .then((response) => {
        const permissionMessage = ["Authentication Successful"];
        if (response.data && !permissionMessage.includes(response.data.message)) {
          dispatch(SignInFailure("You Do Not Have The Required Permissions. Please Contact Your Company Admin."));
        } else {
          dispatch(SignInSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(
          SignInFailure((error.response.data && error.response.data.message) || "Sign-In Failed. Please Try Again.")
        );
      });
  };
}

export function Verify(token) {
  return async (dispatch) => {
    dispatch(VerifyRequest(token));
    dispatch(VerifySuccess());
    // return axios
    //   .get(`${Config.api.endpoint}/users/self`)
    //   .then((response) => {
    //     response = response.data.data;

    //     const data = {
    //       permissions: response.permissions || [],
    //       role: response.roles,
    //     };
    //     dispatch(VerifySuccess(data));
    //   })
    //   .catch((error) => {
    //     dispatch(VerifyFailure());
    //   });
  };
}

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
function SignInRequest(data) {
  return {
    type: SIGNIN_REQUEST,
    data,
  };
}

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
function SignInSuccess(data) {
  setUser(data);
  setToken(data.access_token);
  return {
    type: SIGNIN_SUCCESS,
    data,
  };
}

export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
function SignInFailure(error) {
  return {
    type: SIGNIN_FAILURE,
    error,
  };
}

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export function VerifyRequest(token) {
  setToken(token);
  return {
    type: VERIFY_REQUEST,
  };
}

export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export function VerifySuccess(data) {
  // setUser(data);
  return {
    type: VERIFY_SUCCESS,
    data,
  };
}

export const VERIFY_FAILURE = "VERIFY_FAILURE";
export function VerifyFailure() {
  return {
    type: VERIFY_FAILURE,
  };
}

export const CONFIGURE_PROFILE = "CONFIGURE_PROFILE";
export function ConfigureProfile(data) {
  return {
    type: CONFIGURE_PROFILE,
    data,
  };
}

export const SIGNOUT = "SIGNOUT";
export function SignOut() {
  clearToken();
  return {
    type: SIGNOUT,
  };
}

import Button from "@alliancesafetycouncil/asc-button";
import React, { createRef, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { getDocumentSignedURL } from "../../actions/Sites/Sites";
import { DocumentIcon } from "../Table/DocumentIcon";

const getFileExt = (fileName) => {
  const split = fileName.split(".");
  return split[split.length - 1];
};

/**
 *
 * @param { File } [file] file
 * @param { Function } [report] a callback to report upload progress
 * @param { Function } [cb] callback that fires on successful upload
 * @returns void
 */
export async function handleUpload(file, report, updateFile = () => null) {
  const output = await getDocumentSignedURL(file.name);
  const xhr = new XMLHttpRequest();
  let error = false;

  xhr.upload.addEventListener("progress", (event) => {
    report({
      value: Math.floor((event.loaded / event.total) * 100),
    });
  });

  xhr.upload.addEventListener("error", (e) => {
    error = true;
    report({
      value: 0,
      error: "Upload failed, please try again.",
    });
  });

  xhr.upload.addEventListener("loadend", (e) => {
    updateFile(!error, output.objectPath);
  });

  xhr.open("PUT", output.signedUrl);
  // xhr.setRequestHeader("Content-Disposition", `attachment; filename="${file.name}"`);

  xhr.send(await file.arrayBuffer());
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
`;

const UploadText = styled.div`
  margin: 10px 0px 10px 0px;
  text-align: center;
`;

const dropzoneRef = createRef();
const openDialog = () => {
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};

const CircleBody = styled.svg`
  transform: rotate(-90deg);
  margin-right: 5px;
`;

const CircleFill = styled.circle`
  fill: none;
  stroke: #85ba2f;
  stroke-opacity: 0.3;
  stroke-opacity: 1;
  stroke-linecap: butt;
  stroke-width: 10px;
  stroke-dasharray: var(--initialStroke);
  stroke-dashoffset: var(--initialStroke);
  transition: stroke-dashoffset 900ms ease;
  --initialStroke: 32;
  --transitionDuration: 900ms;
`;

const Circle = ({ progress }) => {
  // initial value equal to initialStroke value to get 0%
  const init = 32;
  // subtract progerss percentage from initial value
  const current = init - (init / 100) * progress;

  return (
    <CircleBody width="20" height="20">
      <CircleFill
        style={{
          strokeDashoffset: current,
        }}
        cx="10"
        cy="10"
        r="5"
        stroke="grey"
      ></CircleFill>
    </CircleBody>
  );
};

const UploadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
`;

const UploadError = styled.span`
  color: red;
`;

const Upload = ({ progress }) => {
  return (
    <UploadContainer>
      <Circle progress={progress.value} />
      {progress.error ? <UploadError>{progress.error}</UploadError> : `${progress.value}%`}
    </UploadContainer>
  );
};

const FileContainer = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding: 10px 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

// invalidators

const invalidExtension = (ext) => {
  const validExtensions = ["doc", "docx", "pdf"];
  if (!validExtensions.includes(ext)) {
    return `Failed, invalid file type`;
  }

  return false;
};

/**
 * Check the file for possible errors in the event it fails to upload
 *
 * @param { File } file file
 * @returns an error response in string format
 */
const validateFile = (file) => {
  const extensionError = invalidExtension(getFileExt(file.name));
  if (extensionError) {
    return extensionError;
  }

  return false;
};

const File = ({ file, updateFile, start }) => {
  const ext = getFileExt(file.name);
  const [progress, setProgress] = useState({
    value: 0,
  });

  useEffect(() => {
    if (!start) return;

    const invalidFile = validateFile(file);
    if (invalidFile) {
      updateFile(false);
      setProgress({
        value: 0,
        error: invalidFile,
      });
      return;
    }

    handleUpload(file, setProgress, updateFile);
  }, [file, start]);

  return (
    <FileContainer>
      <DocumentIcon type={ext} color="black" />
      <p>{file.name}</p>
      <Upload size={file.size} progress={progress} />
    </FileContainer>
  );
};

/**
 *
 * @param { string } label text heading
 * @param { function } toggle hide the modal
 * @param { function } handleUpload callback that fires when all uploads are complete.
 */
const FileInput = ({ label, toggle, handleUpload, modal = false }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [uploads, setUploads] = useState([]);
  const [uploadsComplete, setUploadsComplete] = useState(false);
  let failures = 0;

  /**
   * A function that gets called after every upload, to determine when all uploads have been completed.
   *
   * @param { number } index specifies which file in the list to update
   * @param { boolean } uploadComplete did the upload finish
   * @param { string } path where the file was uploaded
   */
  const handleUpdateFile = (index, uploadComplete, path) => {
    if (uploadComplete) {
      uploads.push({
        uploadComplete,
        name: acceptedFiles[index].name,
        key: path,
        type: getFileExt(acceptedFiles[index].name),
      });

      setUploads(uploads);
    } else {
      failures += 1;
    }

    // if all uploads have resolved, create record for successfull uploads.
    if (uploads.length === acceptedFiles.length - failures) {
      if (handleUpload && uploads.length) handleUpload(uploads);
      setUploadsComplete(true);
    }
  };

  return (
    <Container>
      <label className="primary-heading">{label}</label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <i className="fal fa-cloud-upload fa-3x"></i>
        <UploadText>
          <p>Drag and drop your file here.</p>
          <p>Or...</p>
        </UploadText>
        <Button
          type="secondary"
          size="sm"
          onClick={() => {
            openDialog();
            setUploads([]);
          }}
        >
          Choose File
        </Button>
      </div>
      <aside>
        <ul>
          {acceptedFiles.map((file, index) => (
            <li key={file.path}>
              <File
                start={true}
                file={file}
                updateFile={(uploadStatus, path) => handleUpdateFile(index, uploadStatus, path)}
              />
            </li>
          ))}
        </ul>
      </aside>
      {modal && uploadsComplete && (
        <ButtonContainer>
          <Button type="primary" size="sm" onClick={toggle} id="upload-done">
            Done
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default FileInput;

import {
  ACK_TOAST,
  ADD_ENTITY_FAILURE,
  ADD_ENTITY_REQUEST,
  ADD_ENTITY_SUCCESS,
  EDIT_ENTITY_FAILURE,
  EDIT_ENTITY_REQUEST,
  EDIT_ENTITY_SUCCESS,
} from "../../actions/Entity/Entity";

const InitialState = {
  busy: false,
  res: false,
  err: false,
};

export default function entityService(state = InitialState, action) {
  switch (action.type) {
    case EDIT_ENTITY_REQUEST:
      return {
        ...state,
        busy: true,
      };
    case EDIT_ENTITY_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
      };
    case EDIT_ENTITY_FAILURE:
      return {
        ...state,
        busy: false,
        err: "Failed to edit entity",
      };
    case ADD_ENTITY_REQUEST:
      return {
        ...state,
        busy: true,
      };
    case ADD_ENTITY_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        ...action.data.data,
      };
    case ADD_ENTITY_FAILURE:
      console.log(action);
      return {
        ...state,
        busy: false,
        err: "Failed to add entity",
      };
    case ACK_TOAST:
      return {
        ...InitialState,
      };
    default:
      return state;
  }
}

import Table from "@alliancesafetycouncil/asc-table";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSiteByCompanyId } from "../../../actions/Sites/Sites";

class SitesTab extends Component {
  constructor(props) {
    super(props);

    // columnDefs will define the ag grid columns and cells
    const columnDefs = [{ headerName: "Site", field: "name", cellRenderer: "renderName" }];

    // this config will add buttons to the ag grid header component
    const config = [];

    // Update configs if authed
    if (props.auth) {
      columnDefs.push(
        { headerName: "Contact", field: "contact.name" },
        { headerName: "Email", field: "contact.email" },
        { headerName: "Phone", field: "contact.phone" }
      );

      config.push({
        type: "primary",
        size: "md",
        label: "Add Site",
        href: `/#/companies/add-site/${props.match.params.id}`,
      });
    }

    this.state = {
      columnDefs: columnDefs,
      customCells: {
        renderName: (props) => {
          return props.data && <Link to={`/sites/site-details/${props.data.id}/details`}>{props.data.name}</Link>;
        },
      },
      options: [],
      config: config,
    };
  }

  componentWillMount() {
    this.props.GetSiteByCompanyId(this.props.match.params.id);
  }

  getGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  render() {
    const { data } = this.props;

    return (
      <Table
        {...this.props}
        className="ag-theme-alpine flex flex-1 flex-col"
        columnDefs={this.state.columnDefs}
        getData={this.getSitesById}
        data={data}
        customCells={this.state.customCells}
        filter_type="any"
        getGridApi={this.getGridApi}
        headerConfig={this.state.config}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    data: state.siteService.data,
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSiteByCompanyId: (id) => dispatch(getSiteByCompanyId(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SitesTab);

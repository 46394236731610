import React from "react";

export const UserProfile = () => {
  return (
    <div>
      <div className="h-32px w-32px bg-gray-500 text-white rounded-full text-center mx-2">
        <i className="fal fa-user text-xl inline-block pt-6px -ml-1px"></i>
      </div>
    </div>
  );
};

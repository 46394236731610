import React, { useState } from "react";
import styled from "styled-components";
import { getFavourites } from "../../library/Utilities";

export const Icon = styled.i`
  color: #eeb32f;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const isFavourite = (siteId, override = false) => {
  if (override) {
    return true;
  }

  return getFavourites().includes(siteId);
};

const updateFavourites = (fav, siteId, override = false) => {
  if (fav) {
    const favourites = getFavourites();
    favourites.push(siteId);
    localStorage.setItem("favourites", JSON.stringify(favourites));
  } else {
    const favourites = getFavourites();
    localStorage.setItem("favourites", JSON.stringify(favourites.filter((id) => id !== siteId)));
  }
};

export const FavouriteIcon = ({ style = {}, siteId, override, cb = () => null }) => {
  const [fav, setFav] = useState(isFavourite(siteId, override));

  const handleFavourite = () => {
    updateFavourites(!fav, siteId, override);
    cb();
    return setFav(!fav);
  };

  return <Icon style={style} className={`${fav ? "fas" : "fal"} fa-heart`} onClick={handleFavourite} />;
};

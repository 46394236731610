import ASCButton from "@alliancesafetycouncil/asc-button";
import MainCard from "@alliancesafetycouncil/asc-card";
import React from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { Field, Form, reduxForm } from "redux-form";
import SelectInput from "../../../components/FormContainer/SelectInput";
import TextInput from "../../../components/FormContainer/TextInput";
import TextEditor from "../../../components/TextEditor/TextEditor";
import { required, validEmail, validNumber, validPhone } from "../../../library/Validation";

let AddSiteForm = ({ details, setRef, handleSubmit, pristine, submitting, companies = [], states = [], history }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name"
                id="name"
                placeholder=""
                label="Site Name"
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="address"
                name="address"
                className="form-control"
                label="Address"
                validate={[required]}
                component={TextInput}
              ></Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="city"
                name="city"
                className="form-control"
                label="City"
                validate={[required]}
                component={TextInput}
              ></Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="state"
                name="state"
                className="form-control"
                label="State"
                validate={[required]}
                component={SelectInput}
              >
                <option value={undefined}>Please Select</option>
                {states.map((state) => {
                  return (
                    <option key={state.code} value={state.code}>
                      {state.name}
                    </option>
                  );
                })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="zip"
                name="zip"
                className="form-control"
                label="Zip"
                validate={[required, validNumber]}
                component={TextInput}
              ></Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="company"
                name="company_id"
                component={SelectInput}
                className="form-control"
                label="Company"
                validate={[required]}
                disabled={true}
              >
                <option value={undefined}>Please Select</option>
                {companies.map((company) => {
                  return (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  );
                })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="contact"
                name="contact.name"
                className="form-control"
                label="Contact"
                validate={[required]}
                component={TextInput}
              ></Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="contact"
                name="contact.email"
                className="form-control"
                label="Email"
                validate={[required, validEmail]}
                component={TextInput}
              ></Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                id="phone"
                name="contact.phone"
                className="form-control"
                label="Phone"
                validate={[required, validPhone]}
                component={TextInput}
              ></Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <TextEditor setRef={setRef} initialValue={details.notes} />
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={pristine || submitting}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

AddSiteForm = reduxForm({
  form: "addSite",
})(AddSiteForm);

export default AddSiteForm;

import { BreadCrumbs } from "@alliancesafetycouncil/asc-breadcrumbs";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import FormContainer from "../../../components/FormContainer/FormContainer";
import { HeaderActions } from "../../../components/HeaderActions/HeaderActions";
import { Header } from "../../../containers/Layout/Header";
import AddCompanyForm from "./AddCompanyForm";

class AddCompany extends Component {
  setRef = (ref) => {
    this.setState({
      editorRef: ref,
    });
  };

  render() {
    const links = [
      {
        title: "All Companies",
        link: "/#/companies",
      },
      {
        title: "Add Company",
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-full">
        <Header>
          <div className="pr-6 w-4/6">
            <BreadCrumbs crumbs={links} />
          </div>
          <div className="w-2/6">{this.props.auth && <HeaderActions />}</div>
        </Header>
        <div className="row header no-flex mx-4 h-20 flex items-center">
          <PageTitle>
            <i className="fal fa-building text-orange-500 mr-2"></i>Add Company
          </PageTitle>
        </div>
        <div className="mb-4 content flex-auto mx-4">
          <FormContainer
            {...this.props}
            setRef={this.setRef}
            entity="companies"
            action="add"
            redirect={`/companies`}
            Form={AddCompanyForm}
            handleValues={(values) => {
              return {
                ...values,
                notes: this.state.editorRef.getContent(),
              };
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);

import Card from "@alliancesafetycouncil/asc-card";
import React from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { Field, Form, reduxForm } from "redux-form";
import FileInput from "../../../components/FormContainer/FileInput";
import TextInput from "../../../components/FormContainer/TextInput";
import TextEditor from "../../../components/TextEditor/TextEditor";

import { required } from "../../../library/Validation";

let AddRequirementForm = ({ handleSubmit, pristine, busy, history, setDocuments, setRef, initialValues }) => {
  const handleUpload = (files) => {
    setDocuments(files);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="reqName"
                name="name"
                component={TextInput}
                type="text"
                label="Requirement Name"
                placeholder=""
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            {/* <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Notes
              </Label>
              <Field id="notes" name="notes" component="textarea" className="form-control" rows={16} />
            </FormGroup> */}
            <Row form>
              <Col lg={12}>
                <TextEditor setRef={setRef} initialValue={initialValues} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <FileInput id="document" name="document" label="Upload Documents" handleUpload={handleUpload} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Button color="primary" disabled={pristine || busy}>
                Save
              </Button>{" "}
              <Button color="secondary" onClick={() => history.goBack()}>
                Cancel
              </Button>{" "}
            </FormGroup>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

AddRequirementForm = reduxForm({
  form: "addRequirement",
})(AddRequirementForm);

export default AddRequirementForm;

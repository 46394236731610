import { BreadCrumbs } from "@alliancesafetycouncil/asc-breadcrumbs";
import Card from "@alliancesafetycouncil/asc-card";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Table from "@alliancesafetycouncil/asc-table";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSites } from "../../../actions/Sites/Sites";
import { HeaderActions } from "../../../components/HeaderActions/HeaderActions";
import { FavouriteIcon } from "../../../components/Table/FavouriteIcon";
import { Header } from "../../../containers/Layout/Header";
import { getFavourites } from "../../../library/Utilities";

class Sites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favouriteSites: [],
      params: {
        direction: "asc",
        limit: 100,
        offset: 0,
        sort: "id",
      },
      columnDefs: [
        { headerName: "Site", field: "name", cellRenderer: "renderSiteLink" },
        { headerName: "Company", field: "company.name", cellRenderer: "renderCompanyLink" },
        { headerName: "Contact", field: "contact.name" },
        { headerName: "Phone", field: "contact.phone" },
        { headerName: "Email", field: "contact.email" },
        { headerName: "Site Requirements", field: "requirements" },
        { headerName: "", field: "favourite", maxWidth: 75, suppressMenu: true, cellRenderer: "renderFavourite" },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
      },
      customCells: {
        renderSiteLink: (row) => {
          return <Link to={`/sites/site-details/${row.data.id}/details`}>{row.data.name}</Link>;
        },
        renderCompanyLink: (row) => {
          return <Link to={`/companies/company-details/${row.data.id}/sites`}>{row.data.company.name}</Link>;
        },
        renderFavourite: (row) => {
          return <FavouriteIcon siteId={row.data.id} override={true} cb={this.getFavouriteSites} />;
        },
      },
      config: [
        {
          type: "secondary",
          size: "md",
          label: "Export",
          action: () => this.gridApi.exportDataAsExcel(),
        },
      ],
    };
  }

  getFavouriteSites = () => {
    const { data } = this.props;
    const favourites = getFavourites();

    this.setState({
      favouriteSites: data.filter((site) => favourites.includes(site.id)),
    });
  };

  componentWillMount() {
    const { direction, limit, offset, sort } = this.state.params;

    this.props.GetSites(direction, limit, offset, sort);
  }

  componentDidUpdate(props, _) {
    if (this.props.data.length > props.data.length) {
      this.getFavouriteSites();
    }
  }

  getGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  render() {
    let { favouriteSites } = this.state;

    const links = [
      {
        title: "All Sites",
      },
    ];

    return (
      <div className="flex flex-col bg-blue-50 h-full">
        <Header>
          <div className="pr-6 w-4/6">
            <BreadCrumbs crumbs={links} />
          </div>
          <div className="w-2/6">{this.props.auth && <HeaderActions />}</div>
        </Header>
        <div className="row header flex-initial mx-4 h-20 flex items-center">
          <PageTitle>
            <i className="fal fa-building text-orange-500 mr-2"></i>Favorite Sites
          </PageTitle>
        </div>
        <div className="mb-4 row content flex-auto mx-4">
          <Card type="main">
            <Table
              {...this.props}
              className="ag-theme-alpine flex flex-1 flex-col"
              columnDefs={this.state.columnDefs}
              data={favouriteSites}
              config={this.state.config}
              customCells={this.state.customCells}
              defaultColDef={this.state.defaultColDef}
              filter_type="any"
              getGridApi={this.getGridApi}
              headerConfig={this.state.config}
            />
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    data: state.siteService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSites: (direction, limit, offset, sort) => dispatch(getSites(direction, limit, offset, sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sites);

import PageTitle from "@alliancesafetycouncil/asc-page-title";
import Table from "@alliancesafetycouncil/asc-table";
import Moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import styled from "styled-components";
import { addDocuments, deleteItem, getDocuments } from "../../../actions/Sites/Sites";
import FileInput from "../../../components/FormContainer/FileInput";
import DeleteItem from "../../../components/Table/DeleteItem";
import { DocumentIcon } from "../../../components/Table/DocumentIcon";

const CellContainer = styled.div`
  display: flex;

  &.delete {
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

const RenderDocument = ({ row }) => {
  return (
    <CellContainer>
      <DocumentIcon type={row.data.type} color="#1B91C7" />
      <a href={row.data.download_url}>{row.data.name}</a>
    </CellContainer>
  );
};

class DocumentTab extends Component {
  constructor(props) {
    super(props);

    // columnDefs will define the ag grid columns and cells
    let columnDefs = [
      { headerName: "Document", field: "document", cellRenderer: "RenderDocument", cellClass: "useDelete" },
      { headerName: "Type", field: "type", cellClass: "useDelete" },
      {
        headerName: "Uploaded",
        field: "uploaded",
        cellRenderer: (data) => {
          return data.data.uploaded && this.formatTime(data.data.uploaded);
        },
        cellClass: "useDelete",
      },
      {
        headerName: "",
        field: "status",
        maxWidth: 75,
      },
    ];

    // this config will add buttons to the ag grid header component
    let config = [];

    // Update configs if authed
    if (props.auth) {
      columnDefs = [
        { headerName: "Document", field: "document", cellRenderer: "RenderDocument", cellClass: "useDelete" },
        { headerName: "Type", field: "type", cellClass: "useDelete" },
        { headerName: "Author", field: "author", cellClass: "useDelete" },
        {
          headerName: "Uploaded",
          field: "uploaded",
          cellRenderer: (data) => {
            return data.data.uploaded && this.formatTime(data.data.uploaded);
          },
          cellClass: "useDelete",
        },
        {
          headerName: "",
          field: "status",
          cellRenderer: "DeleteDocument",
          maxWidth: 75,
        },
      ];

      config.push({
        type: "primary",
        size: "md",
        label: "Add Document",
        action: this.addDocumentModal,
      });
    }

    this.state = {
      params: {
        direction: "asc",
        limit: 100,
        offset: 0,
        sort: "id",
      },
      addDocument: false,
      columnDefs: columnDefs,
      customCells: {
        RenderDocument: (row) => <RenderDocument row={row} />,
        DeleteDocument: (row) => (
          <DeleteItem row={row} callback={this.deleteCallback} deleted={row.data.status === "inactive"} />
        ),
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
      },
      config: config,
    };
  }

  deleteCallback = (row) => {
    const { documents } = this.props;

    documents[row.rowIndex].status = documents[row.rowIndex].status === "inactive" ? "active" : "inactive";

    const status = {
      status: documents[row.rowIndex].status,
    };

    this.gridApi.applyTransaction({ update: [row.data] });
    this.props.DeleteDocument(row.data.id, row.rowIndex, status);
  };

  addDocuments = (files) => {
    const { id } = this.props.match.params;

    const payload = {
      requirement_id: id,
      documents: files.map((file) => {
        delete file.uploadComplete;
        return file;
      }),
    };

    this.props.AddDocuments(payload);
  };

  addDocumentModal = () => {
    this.setState({
      addDocument: !this.state.addDocument,
    });
  };

  getGridApi = (gridApi) => {
    this.gridApi = gridApi;
  };

  componentWillMount() {
    const { id } = this.props.match.params;

    this.props.GetDocuments(id);
  }

  formatTime = (value) => {
    return Moment(value).format("LLLL");
  };

  render() {
    const { documents } = this.props;

    return (
      <React.Fragment>
        <Table
          className="ag-theme-alpine flex flex-1 flex-col"
          columnDefs={this.state.columnDefs}
          data={documents}
          config={this.state.config}
          customCells={this.state.customCells}
          defaultColDef={this.state.defaultColDef}
          filter_type="any"
          getGridApi={this.getGridApi}
          headerConfig={this.state.config}
          rowClassRules={{
            isDeleted: (params) => {
              return params.data.status === "inactive";
            },
          }}
          defaultFilter={{
            column: "status",
            value: "active",
          }}
        />
        <Modal isOpen={this.state.addDocument} toggle={this.addDocumentModal}>
          <ModalHeader>
            <PageTitle>
              <i className="fal fa-building text-orange-500 mr-2"></i>Upload Documents
            </PageTitle>
          </ModalHeader>
          <ModalBody>
            <FileInput
              id="document"
              name="document"
              modal={true}
              toggle={this.addDocumentModal}
              handleUpload={this.addDocuments}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    documents: state.siteService.documents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // GetSitesByNetwork: (networkId) => dispatch(getSitesByNetwork(networkId)),
    AddDocuments: (payload) => dispatch(addDocuments(payload)),
    GetDocuments: (id) => dispatch(getDocuments(id)),
    DeleteDocument: (id, index, status) => dispatch(deleteItem("documents", id, index, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTab);

import AddCompany from "./views/Pages/Companies/AddCompany";
import Companies from "./views/Pages/Companies/Companies";
import CompanyDetails from "./views/Pages/Companies/CompanyDetails";
import EditCompany from "./views/Pages/Companies/EditCompany";
import AddRequirement from "./views/Pages/Requirements/AddRequirement";
import EditRequirement from "./views/Pages/Requirements/EditRequirement";
import RequirementDetails from "./views/Pages/Requirements/RequirementDetails";
import AddSite from "./views/Pages/Sites/AddSite";
import EditSite from "./views/Pages/Sites/EditSite";
import FavouriteSites from "./views/Pages/Sites/FavouriteSites";
import SiteDetails from "./views/Pages/Sites/SiteDetails";
import Sites from "./views/Pages/Sites/Sites";

const routes = [
  { auth: false, path: "/", exact: true, name: "" },
  { auth: true, path: "/companies/add-site/:id", name: "Add Company", component: AddSite },
  { auth: false, path: "/companies/company-details/:id", name: "Edit Company", component: CompanyDetails },
  { auth: true, path: "/companies/edit-company/:id", name: "Edit Company", component: EditCompany },
  { auth: true, path: "/companies/add-company", name: "Add Company", component: AddCompany },
  { auth: false, path: "/companies", name: "Company", component: Companies },
  { auth: false, path: "/sites/site-details/:id", name: "Site Detail", component: SiteDetails },
  { auth: true, path: "/sites/edit-site/:id", name: "Edit Site", component: EditSite },
  { auth: true, path: "/sites/add-site", name: "Add Site", component: AddSite },
  { auth: false, path: "/sites", name: "Sites", component: Sites },
  { auth: true, path: "/favourite-sites", name: "Favourite Sites", component: FavouriteSites },
  {
    auth: false,
    path: "/requirements/requirement-details/:id",
    name: "Requirement Detail",
    component: RequirementDetails,
  },
  { auth: true, path: "/requirements/edit-requirement/:id", name: "Edit Requirement", component: EditRequirement },
  { auth: true, path: "/requirements/add-requirement/:id", name: "Add Requirement", component: AddRequirement },
];

export default routes;

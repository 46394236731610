import React from "react";
import { NavLink } from "react-router-dom";
import LogoLight from "../../assets/img/LogoLight.svg";

export const Sidenav = ({ Profile, SignOut, auth }) => {
  return (
    <div className="h-screen w-56 rounded-tr-xl rounded-br-xl bg-blue-900 z-40">
      {/* Large Screens */}
      <div className="hidden md:block bg-blue-500 rounded-tr-xl">
        <a className="text-white text-3xl h-20 flex items-center justify-center" href="/">
          <img src={LogoLight} alt="ReadyCheck" className="w-4/5" />
        </a>
      </div>
      {/* Small Screens */}
      <div className="md:hidden py-2 text-center bg-blue-500">
        <div className="text-white text-3xl">
          <span className="font-bold">Menu</span>
        </div>
      </div>
      {/* Menu Items Wrapper */}
      <div className="flex flex-1 flex-col mt-4">
        {auth && (
          <NavLink
            exact
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center mx-3 my-15px px-4 py-10px text-white hover:no-underline"
            to="/favourite-sites"
          >
            <i className="fal fa-star text-xl text-orange-500 mr-2"></i>
            Favorite Sites
          </NavLink>
        )}
        {/* "Companies" Tab for Superadmin */}
        {Profile && Profile.role === "superadmin" && (
          <NavLink
            exact
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
            to="/companies"
          >
            <i className="fal fa-building text-xl text-orange-500 mr-2"></i>
            All Companies
          </NavLink>
        )}
        {/* "All SiteS" Tab for Superadmin and Owneradmin */}
        {Profile && ["superadmin", "owneradmin"].includes(Profile.role) && (
          <NavLink
            exact
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center mx-3 my-10px px-4 py-3 text-white hover:no-underline"
            to="/sites"
          >
            <i className="fal fa-map-marker-alt text-xl text-orange-500 mr-2"></i>
            All Sites
          </NavLink>
        )}
        <NavLink
          exact
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center mx-3 my-15px px-4 py-3 text-white hover:no-underline"
          to="/sign-in"
          onClick={() => SignOut()}
        >
          <i className="fal fa-sign-out-alt text-xl text-orange-500 mr-2"></i>
          Sign {auth ? "Out" : "In"}
        </NavLink>
        {/* <a
          className="text-xl text-white mr-2 flex items-center"
          href="https://alliancesafetycouncil.org/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a> */}
        <a
          className="text-blue-400 flex justify-center absolute w-full bottom-0 mb-4"
          href="https://alliancesafetycouncil.org/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};
